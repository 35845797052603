import React from 'react'
import './App.css'
import Default from './components/theme/Default/Default'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import NewPage from './components/newPage/NewPage';
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  return (
    // <Router>
    // <Default />
    // </Router>
    <Router>
    <Routes>
      <Route path="/*" element={<Default />} />
      <Route path="/newpage" element={<NewPage />}  />
    </Routes>
  </Router>
  )
}

export default App