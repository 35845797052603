import React, { useRef, useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { FiLoader } from "react-icons/fi";

import axios from 'axios'

const stripePromise = loadStripe(
  "pk_test_51MlS4jSB8mW44FUFGJqTzO57L7JCZkn8CSWPFszbqm3ejKWlh5mkWxbRQrT63s3imj4gCFBGnfzIsd99mPNRPWrp00cBJFTsBg"
);

const PaymentForm = (props) => {


  const { submitPyament, downLoadStart = false, onHide } = props;

  // console.log('downLoadStart', downLoadStart)


  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const paymentFormRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const confirmPayment = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    });

    setIsLoading(false);

    if (confirmPayment?.error) {
      if (confirmPayment.error.type === "card_error" || confirmPayment.error.type === "validation_error") {
        setMessage(confirmPayment.error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
    } else {
      // console.log("paymentIntent", confirmPayment);
      if (downLoadStart) {
        onHide();
        downLoadStart()
        // props.onPaymentComplete(confirmPayment.paymentIntent);

      }
    }
  }


  const handleFormSubmitEvent = () => {
    paymentFormRef.current.dispatchEvent(
      new Event("submit", { bubbles: true, cancelable: true })
    );
  };


  useEffect(() => {


    // console.log('submitPyament', submitPyament)


    if (submitPyament) {

      handleFormSubmitEvent();

    }

  }, [submitPyament])

  return (
    <div id="payment-element">
      <form ref={paymentFormRef} id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
      </form>
      {message && <div id="payment-message" className="text-danger">{message}</div>}
    </div>
  );
}

const PaymentsModel = (props) => {


  const { downLoadStart, amount, onHide } = props;

  // console.log('downLoadStart', downLoadStart)


  const [clientSecret, setClientSecret] = useState(false);
  const [submitPyament, setSubmitPayment] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handlePayNow = () => {

    setSubmitPayment(true)
    setShowLoader(true);

  }


  const loadPaymentDetails = async () => {
    try {

      const response = await axios.post('https://asservices.in/reelshop-kd-master/api/v1/payment-sheet-test');
      setClientSecret(response.data.paymentIntent);
    } catch (error) {
      // console.log(error);
      setClientSecret(false);
    }
  };


  useEffect(() => {
    loadPaymentDetails()
  }, [])


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      onHide={true}
    >

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Payment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {clientSecret && <Elements stripe={stripePromise} options={{ clientSecret }}>

          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>

          <PaymentForm onHide={props.onHide} submitPyament={submitPyament} downLoadStart={downLoadStart} />

        </Elements>
        }

      </Modal.Body>
      <Modal.Footer>
        <Button disabled={showLoader} variant='dark' onClick={handlePayNow}>
          {showLoader && <FiLoader className='spin' />} Pay $ {amount.toFixed(2)}
        </Button>
        <Button variant='secondary' onClick={props.onHide}>Close</Button>

      </Modal.Footer>
    </Modal>
  );
}



export default PaymentsModel;