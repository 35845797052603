// import React, { useState } from 'react'
// import Container from 'react-bootstrap/Container';
// import Navbar from 'react-bootstrap/Navbar';
// import { HiMiniMapPin } from "react-icons/hi2";
// import InputGroup from 'react-bootstrap/InputGroup';
// import Form from 'react-bootstrap/Form';
// import { OpenStreetMapProvider } from 'leaflet-geosearch';
// import { FaLocationDot } from "react-icons/fa6";
// import { CiSearch } from "react-icons/ci";
// import './header.css'
// import LoginAndRegister from '../../auth/LoginAndRegister';
// import Button from 'react-bootstrap/esm/Button';


// const provider = new OpenStreetMapProvider();
// export const Header = ({ receivedData = false, props, handleMapCenter, onLogoClick  }) => {

//   const [showLoginModel, setShowLoginModel] = React.useState(false);

//   const [email, setEmail] = useState(localStorage.getItem('id'))
//   const [showSearchModal, setShowSearchModal] = useState(false)
//   const [filteredLocations, setFilteredLocations] = useState([]);

//   const handleLogOut = () => {
//     localStorage.clear('id')
//     window.location.reload()
//   }
//   const handleSearch = (e) => {

//     const searchTerm = e.target.value

//     if (searchTerm.trim() == '') {
//       setShowSearchModal(false)
//       setFilteredLocations([])
//       return;
//     }

//     provider.search({ query: searchTerm }).then(function (result) {
//       setFilteredLocations(result);
//       setShowSearchModal(true);
//     });
//   }
//   const openLocationOnMap = (result) => {
//     // console.log('Opening location on map:', result);
//     handleMapCenter([result.y, result.x])
//     setFilteredLocations([])
//   }
//   return (
//     <Navbar className="bg-body-light header-main">
//       <Container fluid>
//         <div className='d-flex justify-content-between align-items-center w-100'>

//           {/* LOGO */}
//           <div class="logo" onClick={onLogoClick}>
//             <HiMiniMapPin size={30} /> MapInterface
//           </div>
//         <div className='d-flex'> 
//           <InputGroup className="mb-4 mt-4" style={{ position: 'relative' ,width: '300px' }}>
//         <Form.Control
//           onChange={handleSearch}
//           placeholder="Enter an address in LA county..."
//           aria-label="location"
//           aria-describedby="location"
          
//         />
//         <InputGroup.Text className="bg-dark text-light" id="location" >
//           <CiSearch />
//         </InputGroup.Text>
//       </InputGroup>
      
//       {showSearchModal && filteredLocations.length != 0 && <div className="search-area">
//         <ul>
//           {
//             filteredLocations.map((result, index) =>
//               <li key={index} onClick={() => openLocationOnMap(result)}>
//                 <FaLocationDot /> &nbsp;
//                 <span>{result.label}</span>
//               </li>
//             )}
//         </ul>
//       </div>}
//       </div>
//           <div className='d-flex'>

//             {email &&
//               <div>
//                 <span className='me-4'> {email} </span>
//                 <Button variant='dark' onClick={handleLogOut}> Logout </Button>
//               </div>
//             }

//             {!email &&
//               <div onClick={() => setShowLoginModel(true)} className='pointer me-2'>
//                 Login / Register
//               </div>
//             }

//           </div>
//         </div>
//       </Container>

//       <LoginAndRegister
//         show={showLoginModel}
//         onHide={() => setShowLoginModel(false)}
//         callback={setEmail}
//       />

//     </Navbar>
//   )
// }


import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { HiMiniMapPin } from "react-icons/hi2";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { FaLocationDot } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import './header.css'
import LoginAndRegister from '../../auth/LoginAndRegister';
import Button from 'react-bootstrap/esm/Button';


const provider = new OpenStreetMapProvider();
export const Header = ({ receivedData = false, props, handleMapCenter, onLogoClick  }) => {

  const [showLoginModel, setShowLoginModel] = React.useState(false);

  const [email, setEmail] = useState(localStorage.getItem('id'))
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedCity, setSelectedCity] = useState('Los Angeles');
  const [selectedState, setSelectedState] = useState('California');


  
  const handleLogOut = () => {
    localStorage.clear('id')
    window.location.reload()
  }
  const handleSearch = (e) => {

    const searchTerm = e.target.value

    if (searchTerm.trim() == '') {
      setShowSearchModal(false)
      setFilteredLocations([])
      return;
    }

    const fullSearchTerm = `${searchTerm} ${selectedCity} ${selectedState}`;

    provider.search({ query: fullSearchTerm }).then(function (result) {
     
      setFilteredLocations(result);
      setShowSearchModal(true);
    });
  
  }
  const openLocationOnMap = (result) => {
    // console.log('Opening location on map:', result);
    handleMapCenter([result.y, result.x],24)
    setFilteredLocations([])
  }
  return (
    <Navbar className="bg-body-light header-main">
      <Container fluid>
        <div className='d-flex justify-content-between align-items-center w-100'>

          {/* LOGO */}
          <div className="logo" onClick={onLogoClick}>
            <HiMiniMapPin size={30} /> MapInterface
          </div>
        <div className='d-flex'> 
          <InputGroup className="mb-4 mt-4" style={{ position: 'relative' ,width: '300px' }}>
        <Form.Control
          onChange={handleSearch}
          placeholder="Enter an address in LA county..."
          aria-label="location"
          aria-describedby="location"
          
        />
        <InputGroup.Text className="bg-dark text-light" id="location" >
          <CiSearch />
        </InputGroup.Text>
      </InputGroup>
      
      {showSearchModal && filteredLocations.length != 0 && <div className="search-area">
        <ul>
          {
            filteredLocations.map((result, index) =>
              <li key={index} onClick={() => openLocationOnMap(result)}>
                <FaLocationDot /> &nbsp;
                <span>{result.label}</span>
              </li>
            )}
        </ul>
      </div>}
      </div>
          <div className='d-flex'>

            {email &&
              <div>
                <span className='me-4'> {email} </span>
                <Button variant='dark' onClick={handleLogOut}> Logout </Button>
              </div>
            }

            {!email &&
              <div onClick={() => setShowLoginModel(true)} className='pointer me-2'>
                Login / Register
              </div>
            }

          </div>
        </div>
      </Container>

      <LoginAndRegister
        show={showLoginModel}
        onHide={() => setShowLoginModel(false)}
        callback={setEmail}
      />

    </Navbar>
  )
}
