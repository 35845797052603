export const calculateDistance = (bounds) => {
    // Get the corner points of the bounds
    var sw = bounds.getSouthWest();
    var ne = bounds.getNorthEast();

    // Calculate the distances between the latitude and longitude points
    var latDistance = ne.lat - sw.lat;
    var lonDistance = ne.lng - sw.lng;

    // Convert distances to kilometers (assuming 1 degree of latitude ~ 111.32 km and 1 degree of longitude ~ 111.32 km at the equator)
    var latDistanceKm = latDistance * 111.32;
    var lonDistanceKm = lonDistance * 111.32 * Math.cos((sw.lat + ne.lat) * Math.PI / 360);

    // Calculate the area in square kilometers
    var areaSqKm = latDistanceKm * lonDistanceKm;
    return areaSqKm;
}
