// import React, { useState, useEffect } from 'react';
// import { Header } from '../Header/Header';
// import { Sidebar } from '../Sidebar/Sidebar';
// import { NewSidebar } from '../Sidebar/newSidebar';
// import './default.css';
// import MapInterface from '../../map/MapInterface';
// import WelcomeModal from '../../WelcomeModal/welcomeModal';
// import NewPage from '../../newPage/NewPage';

// const Default = (props) => {
//   const [showMap, setShowMap] = useState(true); // State to manage visibility of MapInterface
//   const [showModal, setShowModal] = useState(true); // State to control the modal visibility
//   const [modelLoaded, setModelLoaded] = useState(false); // State to manage if the model is loaded
//   const [receivedData, setReceivedData] = useState(null); // Changed initial state to null
//   const [mapCenter, setMapCenter] = useState([34.052235, -118.243683]);

//   // Handler to close the modal
//   const handleClose = () => setShowModal(false);

//   // Set showModal to true when the component mounts
//   useEffect(() => {
//     setShowModal(true);
//   }, []);

//   const handleSendData = (data) => {
//     setReceivedData(data);
//   };

//   const handleButtonClick = () => {
//     setShowMap(false); // Hide MapInterface and show NewComponent
//   };

//   const handleModelLoad = () => {
//     setModelLoaded(true);
//   };

//   const handleLogoClick = () => {
//     setShowMap(true); // Show MapInterface and old Sidebar when logo is clicked
//     setModelLoaded(false); // Reset the model loaded state
//     setReceivedData(null); // Reset received data if necessary
//   };

//   return (
//     <main>
//       <div className="App">
//         <WelcomeModal showModal={showModal} handleClose={handleClose} />
//       </div>
//       <Header handleMapCenter={setMapCenter} receivedData={receivedData} onLogoClick={handleLogoClick} />
//       <div className='d-flex'>
//         <div className='sidebar-main'>
//           {showMap ? (
//             <Sidebar handleMapCenter={setMapCenter} receivedData={receivedData} />
//           ) : (
//             <NewSidebar handleMapCenter={setMapCenter} receivedData={receivedData} modelLoaded={modelLoaded} />
//           )}
//         </div>
//         <div className='content-main'>
//           {showMap ? (
//             <MapInterface mapCenter={mapCenter} sendData={handleSendData} onButtonClick={handleButtonClick} />
//           ) : (
//             receivedData && (
//               <NewPage
//                 receivedData={receivedData}
//                 onLoad={handleModelLoad}
//               />
//             )
//           )}
//         </div>
//       </div>
//     </main>
//   );
// };

// export default Default;


// import React, { useState, useEffect } from 'react';
// import { Header } from '../Header/Header';
// import { Sidebar } from '../Sidebar/Sidebar';
// import { NewSidebar } from '../Sidebar/newSidebar';
// import './default.css';
// import MapInterface from '../../map/MapInterface';
// import WelcomeModal from '../../WelcomeModal/welcomeModal';
// import NewPage from '../../newPage/NewPage';

// const Default = (props) => {
//   const [showMap, setShowMap] = useState(true); // State to manage visibility of MapInterface
//   const [showModal, setShowModal] = useState(true); // State to control the modal visibility
//   const [modelLoaded, setModelLoaded] = useState(false); // State to manage if the model is loaded
//   const [receivedData, setReceivedData] = useState(false);
//   const [mapCenter, setMapCenter] = useState([34.052235, -118.243683]);

//   // Handler to close the modal
//   const handleClose = () => setShowModal(false);

//   // Set showModal to true when the component mounts
//   useEffect(() => {
//     setShowModal(true);
//   }, []);

//   const handleSendData = (data) => {
//     setReceivedData(data);
//   };

//   const handleButtonClick = () => {
//     setShowMap(false); // Hide MapInterface and show NewComponent
//   };

//   const handleModelLoad = () => {
//     setModelLoaded(true);
//   };

//   const handleLogoClick = () => {
//     setShowMap(true); // Show MapInterface and old Sidebar when logo is clicked
//     setModelLoaded(false); // Reset the model loaded state
//     setReceivedData(false); // Reset received data if necessary
//   };

//   return (
//     <main>
//       <div className="App">
//         <WelcomeModal showModal={showModal} handleClose={handleClose} />
//       </div>
//       <Header handleMapCenter={setMapCenter} receivedData={receivedData} onLogoClick={handleLogoClick} />
//       <div className='d-flex'>
//         <div className='sidebar-main'>
//           {showMap ? (
//             <Sidebar handleMapCenter={setMapCenter} receivedData={receivedData} />
//           ) : (
//             <NewSidebar handleMapCenter={setMapCenter} receivedData={receivedData} modelLoaded={modelLoaded} />
//           )}
//         </div>
//         <div className='content-main'>
//           {showMap ? (
//             <MapInterface mapCenter={mapCenter} sendData={handleSendData} onButtonClick={handleButtonClick} />
//           ) : (
//             <NewPage receivedData={receivedData} onLoad={handleModelLoad} />
//           )}
//         </div>
//       </div>
//     </main>
//   );
// };

// export default Default;


import React, { useState, useEffect } from 'react';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import { NewSidebar } from '../Sidebar/newSidebar';
import './default.css';
import MapInterface from '../../map/MapInterface';
import WelcomeModal from '../../WelcomeModal/welcomeModal';
import NewPage from '../../newPage/NewPage';

const Default = (props) => {
  const [showMap, setShowMap] = useState(true); // State to manage visibility of MapInterface
  const [showModal, setShowModal] = useState(true); // State to control the modal visibility
  const [modelLoaded, setModelLoaded] = useState(false); // State to manage if the model is loaded
  const [receivedData, setReceivedData] = useState(null); // Changed initial state to null
  const [mapCenter, setMapCenter] = useState([34.052235, -118.243683]);
  const [zoomLevel, setZoomLevel] = useState(13);
  // Handler to close the modal
  const handleClose = () => setShowModal(false);

  // Set showModal to true when the component mounts
  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleSendData = (data) => {
    setReceivedData(data);
  };
  const handleMapCenter = (center, zoom) => {
    setMapCenter(center);
    setZoomLevel(zoom);
  };
  const handleButtonClick = () => {
    setShowMap(false); // Hide MapInterface and show NewComponent
  };

  const handleModelLoad = () => {
    setModelLoaded(true);
  };

  const handleLogoClick = () => {
    setShowMap(true); // Show MapInterface and old Sidebar when logo is clicked
    setModelLoaded(false); // Reset the model loaded state
    setReceivedData(null); // Reset received data if necessary
  };

  return (
    <main>
      <div className="App">
        <WelcomeModal showModal={showModal} handleClose={handleClose} />
      </div>
      <Header handleMapCenter={handleMapCenter} receivedData={receivedData} onLogoClick={handleLogoClick} />
      <div className='d-flex'>
        <div className='sidebar-main'>
          {showMap ? (
            <Sidebar handleMapCenter={handleMapCenter} receivedData={receivedData} />
          ) : (
            <NewSidebar handleMapCenter={handleMapCenter} receivedData={receivedData} modelLoaded={modelLoaded} />
          )}
        </div>
        <div className='content-main'>
          {showMap ? (
            <MapInterface mapCenter={mapCenter} zoomLevel={zoomLevel} sendData={handleSendData} onButtonClick={handleButtonClick} />
          ) : (
            receivedData && (
              <NewPage
                receivedData={receivedData}
                onLoad={handleModelLoad}
              />
            )
          )}
        </div>
      </div>
    </main>
  );
};

export default Default;
