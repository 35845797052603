import React, { Fragment, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";

const LoginAndRegister = (props) => {

  // const [validated, setValidated] = useState(false);
  const [activeTab, setActiveTap] = useState('Login');

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log('HERE')

    const data = new FormData(event.target)
    const URL = `http://198.44.66.10:5001/api/v1/${activeTab == 'Login' ? 'login' : 'register'}`

    try {

      const headers = {
        headers: { "content-type": "application/json" }
      }

      const response = await axios.post(URL, data, headers)
      const responseData = response.data
      // console.log('responseData', responseData.data);
      setActiveTap('Login')
      if (!responseData) throw new Error('')

      localStorage.setItem("id", responseData.data)
      props.callback(responseData.data)

      props.onHide();

    } catch (error) {
      throw new Error(error.message);
    }

  };



  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="login-register-model"
      centered
      backdrop="static"
      keyboard={false}
      show={props.show}
    >
      <form onSubmit={handleSubmit}>

        <Modal.Header closeButton>
          <Modal.Title id="login-register-model">
            {activeTab == 'Login' ? 'Login With Your Account' : 'Create Account'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">

            {activeTab == 'Register' && <Form.Group className="mb-2" as={Col} md="12" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Enter your name"
                defaultValue=""
              />
            </Form.Group>}

            <Form.Group className="mb-2" as={Col} md="12" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="text"
                name="email"
                placeholder="Enter your email address"
                defaultValue=""
              />
            </Form.Group>

            <Form.Group className="mb-2" as={Col} md="12" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Enter your password"
                defaultValue=""
              />
            </Form.Group>


            {activeTab == 'Login'
              ? <Fragment>

                <Col md={12} className="right d-flex justify-content-end">
                  <p className="pointer font-13">Forgot Password?</p>
                </Col>

                <Col md={12} className="right d-flex justify-content-center align-items-end mt-3">
                  <p onClick={() => setActiveTap('Register')} className="text-muted font-13">Don't Have an Account? <span className="font-bold text-dark text-underline pointer"> Register Now </span> </p>
                </Col>

              </Fragment>

              : <Fragment>

                <Col md={12} className="right d-flex justify-content-center align-items-end mt-3">
                  <p onClick={() => setActiveTap('Login')} className="text-muted font-13">Already have an Account? <span className="font-bold text-dark text-underline pointer"> Login Now </span> </p>
                </Col>

              </Fragment>

            }


          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="dark">{activeTab}</Button>
          <Button type="button" variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

        </Modal.Footer>
      </form>

    </Modal>
  );
};

export default LoginAndRegister;
