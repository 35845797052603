// import React, { Fragment, useCallback, useEffect, useState } from 'react';
// import { MapContainer, TileLayer, FeatureGroup, useMap } from 'react-leaflet';
// import { EditControl } from 'react-leaflet-draw';
// import L from 'leaflet'; // Import Leaflet
// import 'leaflet/dist/leaflet.css';
// import 'leaflet-draw/dist/leaflet.draw.css';
// import './map.css';
// import Button from 'react-bootstrap/Button';
// import { useNavigate } from 'react-router-dom';

// // setup
// var bounds;
// var body;

// // Customize tooltips and cursor styles
// L.drawLocal.draw.handlers.rectangle.tooltip.start = 'Click first corner of the area!';
// L.drawLocal.draw.handlers.rectangle.tooltip.cont = 'Click the other corner!';
// L.drawLocal.draw.handlers.rectangle.tooltip.end = 'Click the other corner!';

// // const MapComponent = ({props,onButtonClick }) => {
//   const MapComponent = ({mapCenter, sendData,onButtonClick }) => {
  
//   const navigate = useNavigate();
  
  
//   let buttonComponent;
//   const map = useMap();
//   //const { sendData, mapCenter } = props;
//   //console.log('receivedData', mapCenter)

//   const [drawnLayers, setDrawnLayers] = useState([]);

//   const handleOnCreate = (e) => {

//     const cancelButton = document.querySelector('.leaflet-draw-actions-bottom li a');
//     if (cancelButton) {
//       cancelButton.click();
//     }

//     const { layer } = e;
//     // console.log('layer', layer);
//     setDrawnLayers([layer])
//     bounds=e.layer.getBounds()
//     //console.log("bounds" , bounds)
//     var body = {
//       bbox_min_lon: bounds._northEast.lng,
//       bbox_min_lat: bounds._northEast.lat,
//       bbox_max_lon: bounds._southWest.lng,
//       bbox_max_lat: bounds._southWest.lat,
//     }
    
//     sendData(e.layer.getBounds());
//     // setIsDrawStart(true)
//   }

//   useEffect(() => {
//     map.setView(mapCenter, 13);
//   }, [mapCenter, map]);

//   const enableDrawControl = useCallback(() => {

//     drawnLayers.forEach((drawnLayers) => {
//       drawnLayers.remove()
//     });

//     if (drawnLayers.length !== 0) {
//       setDrawnLayers([]);
//       sendData(false);
//     }

//     const drawControl = document.getElementsByClassName('leaflet-draw-draw-rectangle')[0];
//     // console.log('drawControl', drawControl)
//     if (drawControl) {
//       drawControl.click();
//     }

//   }, [drawnLayers]);


//   if (drawnLayers.length !== 0) {
//     buttonComponent = (
//       <div className="button-container">
//         <Button
//           variant='dark'
//           className='clear-area'
//           onClick={enableDrawControl}
//         >
//           Clear Selection
//         </Button>
//         <Button
//           variant='dark'
//           className='confirm-area'
//           onClick={onButtonClick}
//         >
//           Confirm Selection
//         </Button>
//       </div>
//     );
//   } else {
//     buttonComponent = (
//       <div className="button-container">
//         <Button
//           variant='dark'
//           className='select-area'
//           onClick={enableDrawControl}
//         >
//           Click to Select Area
//         </Button>
//       </div>
//     );
//   }

//   useEffect(() => {
//     const handleDrawStart = () => {
//       map.getContainer().classList.add('crosshair-draw');
//     };

//     const handleDrawStop = () => {
//       map.getContainer().classList.remove('crosshair-draw');
//     };

//     map.on('draw:drawstart', handleDrawStart);
//     map.on('draw:drawstop', handleDrawStop);

//     return () => {
//       map.off('draw:drawstart', handleDrawStart);
//       map.off('draw:drawstop', handleDrawStop);
//     };
//   }, [map]);

//   return (
//     <Fragment>
//       <TileLayer
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />

//       <FeatureGroup>
//         <EditControl
//           position='topright'
//           onCreated={handleOnCreate}
//           edit={{ remove: true, edit: true }}
//           draw={{
//             rectangle: {
//               shapeOptions: {
//                 color: 'black',
//               },
//             },
//             circlemarker: false,
//             circle: false,
//             polygon: false,
//             polyline: false
//           }}
//         />
//       </FeatureGroup>
//       {buttonComponent}
//       {/* <Button
//         variant='dark'
//         className='select-area'
//         onClick={enableDrawControl}
//       >
//         {drawnLayers.length != 0 ? 'Clear Selection' : 'Select Area'}
//       </Button>
//        */}
    
//     </Fragment>
//   )
// }



// const MapInterface = (props) => {


//   return (
//     <MapContainer
//       center={props.mapCenter}
//       zoom={13}
//       className='leaflet-map-container'
//     >
//       <MapComponent {...props} />

//     </MapContainer>
//   );
// };

// export default MapInterface;


import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { MapContainer, TileLayer, FeatureGroup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet'; // Import Leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './map.css';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

// setup
var bounds;
var body;

// Customize tooltips and cursor styles
L.drawLocal.draw.handlers.rectangle.tooltip.start = 'Click first corner of the area!';
L.drawLocal.draw.handlers.rectangle.tooltip.cont = 'Click the other corner!';
L.drawLocal.draw.handlers.rectangle.tooltip.end = 'Click the other corner!';

// const MapComponent = ({props,onButtonClick }) => {
  const MapComponent = ({mapCenter,zoomLevel, sendData,onButtonClick }) => {
  
  const navigate = useNavigate();

  let buttonComponent;
  const map = useMap();
  //const { sendData, mapCenter } = props;
  //console.log('receivedData', mapCenter)

  const [drawnLayers, setDrawnLayers] = useState([]);

  const handleOnCreate = (e) => {

    const cancelButton = document.querySelector('.leaflet-draw-actions-bottom li a');
    if (cancelButton) {
      cancelButton.click();
    }

    const { layer } = e;
    // console.log('layer', layer);
    setDrawnLayers([layer])
    bounds=e.layer.getBounds()
    //console.log("bounds" , bounds)
    var body = {
      bbox_min_lon: bounds._northEast.lng,
      bbox_min_lat: bounds._northEast.lat,
      bbox_max_lon: bounds._southWest.lng,
      bbox_max_lat: bounds._southWest.lat,
    }
    
    sendData(e.layer.getBounds());
    // setIsDrawStart(true)
  }
  useEffect(() => {
    map.setView(mapCenter,zoomLevel);
  }, [mapCenter, zoomLevel,map]);

  const enableDrawControl = useCallback(() => {

    drawnLayers.forEach((drawnLayers) => {
      drawnLayers.remove()
    });

    if (drawnLayers.length !== 0) {
      setDrawnLayers([]);
      sendData(false);
    }

    const drawControl = document.getElementsByClassName('leaflet-draw-draw-rectangle')[0];
    // console.log('drawControl', drawControl)
    if (drawControl) {
      drawControl.click();
    }

  }, [drawnLayers,sendData]);


  if (drawnLayers.length !== 0) {
    buttonComponent = (
      <div className="button-container">
        <Button
          variant='dark'
          className='clear-area'
          onClick={enableDrawControl}
        >
          Clear Selection
        </Button>
        <Button
          variant='dark'
          className='confirm-area'
          onClick={onButtonClick}
        >
          Confirm Selection
        </Button>
      </div>
    );
  } else {
    buttonComponent = (
      <div className="button-container">
        <Button
          variant='dark'
          className='select-area'
          onClick={enableDrawControl}
        >
          Click to Select Area
        </Button>
      </div>
    );
  }

  useEffect(() => {
    const handleDrawStart = () => {
      map.getContainer().classList.add('crosshair-draw');
    };

    const handleDrawStop = () => {
      map.getContainer().classList.remove('crosshair-draw');
    };

    map.on('draw:drawstart', handleDrawStart);
    map.on('draw:drawstop', handleDrawStop);

    return () => {
      map.off('draw:drawstart', handleDrawStart);
      map.off('draw:drawstop', handleDrawStop);
    };
  }, [map]);

  return (
    <Fragment>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <FeatureGroup>
        <EditControl
          position='topright'
          onCreated={handleOnCreate}
          edit={{ remove: true, edit: true }}
          draw={{
            rectangle: {
              shapeOptions: {
                color: 'black',
              },
            },
            circlemarker: false,
            circle: false,
            polygon: false,
            polyline: false
          }}
        />
      </FeatureGroup>
      {buttonComponent}
      {/* <Button
        variant='dark'
        className='select-area'
        onClick={enableDrawControl}
      >
        {drawnLayers.length != 0 ? 'Clear Selection' : 'Select Area'}
      </Button>
       */}
    
    </Fragment>
  )
}



const MapInterface = (props) => {


  return (
    <MapContainer
      center={props.mapCenter}
      zoom={props.zoomLevel}
      className='leaflet-map-container'
    >
      <MapComponent {...props} />

    </MapContainer>
  );
};

export default MapInterface;
