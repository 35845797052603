import React, { useEffect, useState } from 'react';
import './customSpinner.css';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { StlViewer } from 'react-stl-viewer';
import axios from 'axios';

const NewPage = ({ receivedData, onLoad }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [stlUrl, setStlUrl] = useState(`\\dem_mesh.stl?${new Date().getTime()}`); // Initialize with cache-busting URL

  const style = {
    top: 10,
    left: 10,
    width: '100%',
    height: '90%',
    backgroundColor: 'grey', // Set the background color to grey
  };

  const modelProps = {
    color: 'white', // Set the model color to white
  };

  const body = {
    bbox_min_lon: receivedData._northEast.lng,
    bbox_min_lat: receivedData._northEast.lat,
    bbox_max_lon: receivedData._southWest.lng,
    bbox_max_lat: receivedData._southWest.lat,
  };

  useEffect(() => {
    setDataFetched(false); // Reset dataFetched when receivedData changes
  }, [receivedData]);

  useEffect(() => {
    const fetchData = async () => {
      if (dataFetched) return; // Prevent multiple fetches
      setDataFetched(true); // Mark as fetched
      setLoading(true);
      setError(null);
      try {
        const Url = `${window.location.origin}/api/v1/get_dxf_data`;
        const result = await axios.post(Url, body, {
          headers: {
            "Content-Type": 'application/json',
          },
        });

        if (result.data === true) {
          onLoad(); // Notify parent component that the model is loaded
        } else {
          setError('Error generating files.');
        }
      } catch (error) {
        setError('Error fetching data: ' + error.message); // Updated error message to be more generic
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [body, dataFetched, onLoad]);

  const handleStlError = () => {
    setError('Error loading STL model.');
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <Button variant="info" disabled className="custom-spinner">
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true" />
          <span className="spinnertext">Processing Files...</span>
        </Button>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div>
      <div className="container">
        <div className="box box2"> 
          <h9>Zoom in below to view the model if not visible!</h9> 
           <div style={{ position: 'relative', width: '100%', height: '100%' }}>
           <StlViewer
              style={style}
              url={stlUrl}
              orbitControls
              rotate={'true'}
              shadows={true} // Enable shadows
              modelProps={modelProps}
              onError={handleStlError}
            />
          </div>
        </div>
      </div>
   </div>
  );
};

export default NewPage;
