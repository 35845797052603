import Card from 'react-bootstrap/Card';
import { calculateDistance } from "../../../common";

export const CardSidebar = ({receivedData}) => {
  return (
    <>
      {[        
        'Dark',
      ].map((variant) => (
        <Card
          bg={variant.toLowerCase()}
          key={variant}
          text={variant.toLowerCase() === 'light' ? 'dark' : 'white'}
          style={{ width: '16rem' }}
          className="mb-1"
        >
          <Card.Header><strong>Pricing</strong></Card.Header>
          <Card.Body>
            {/* <Card.Title>{variant}  </Card.Title> */}
            <Card.Text>
              <span>$1 per 10k SF  </span> 
              <br />
              <span>Total Price: ${calculateDistance(receivedData).toFixed(2)*10760000*0.001}</span>
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </>
  );
}
