import React, { Fragment, useEffect } from "react";
import "./sidebar.css";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { CiSearch } from "react-icons/ci";
import { GrDownload, GrWifiNone } from "react-icons/gr";
import PaymentsModel from "../../payments/PaymentsModel";
import { useState } from "react";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { FaLocationDot } from "react-icons/fa6";
import axios from "axios";
// import { socket } from "../../../socket";
import { calculateDistance } from "../../../common";


var isDoownloadingInterval = false;

function getCurrentDateTime() {
  var now = new Date();
  var year = now.getFullYear();
  var month = ('0' + (now.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
  var day = ('0' + now.getDate()).slice(-2);
  var hours = ('0' + now.getHours()).slice(-2);
  var minutes = ('0' + now.getMinutes()).slice(-2);
  var seconds = ('0' + now.getSeconds()).slice(-2);

  var formattedDateTime = year + month + day + '_' + hours + minutes + seconds;
  return formattedDateTime;
}



//const provider = new OpenStreetMapProvider();

export const Sidebar = ({ receivedData = false, props, handleMapCenter }) => {

  // console.log('receivedData', receivedData)

  const [ShowPaymentModel, setShowPaymentModel] = React.useState(false);
 // const [filteredLocations, setFilteredLocations] = useState([]);
  const [downloadStatus, setDownloadStatus] = useState(false);
 // const [showSearchModal, setShowSearchModal] = useState(false)


  function onDownloadProgress(value) {
    setDownloadStatus(value)
  }

  // const handleSearch = (e) => {

  //   const searchTerm = e.target.value

  //   if (searchTerm.trim() == '') {
  //     setShowSearchModal(false)
  //     setFilteredLocations([])
  //     return;
  //   }

  //   provider.search({ query: searchTerm }).then(function (result) {
  //     setFilteredLocations(result);
  //     setShowSearchModal(true);
  //   });
  // }


  // const openLocationOnMap = (result) => {
  //   // console.log('Opening location on map:', result);
  //   handleMapCenter([result.y, result.x])
  //   setFilteredLocations([])
  // }

  useEffect(() => {
    
    if(downloadStatus){
      alert('Downloading already in Progress! Please wait to complete it');
      return false;
    }

  },[receivedData])


  // useEffect(() => {
  //   function onConnect() {
  //     console.log('Conected')
  //   }

  //   function onDisconnect() {
  //     console.log('dis Connected')
  //   }


  //   socket.on('connect', onConnect);
  //   socket.on('disconnect', onDisconnect);
  //   socket.on('download_progress', onDownloadProgress);

  //   return () => {
  //     socket.off('connect', onConnect);
  //     socket.off('disconnect', onDisconnect);
  //     socket.off('foo', onDownloadProgress);
  //   };
  // }, []);


  const downloadFile = async () => {

   
    try {
      const downloadUrl = `${window.location.origin}/api/v1/download`;
      // const downloadUrl = `http://198.44.66.10:5001/api/v1/download`;
      // const downloadUrl = `http://densifyla.com/api/v1/download`;
  
      // const body=receivedData
      const body = {
        bbox_min_lon: receivedData._northEast.lng,
        bbox_min_lat: receivedData._northEast.lat,
        bbox_max_lon: receivedData._southWest.lng,
        bbox_max_lat: receivedData._southWest.lat,
      }

      onDownloadProgress({
        status : 'Preparing....'
      })
      

      if(isDoownloadingInterval){
        clearTimeout(isDoownloadingInterval)
      }

      isDoownloadingInterval = setTimeout(() =>{
        onDownloadProgress({
          status : 'Downloadig....'
        })
    
      },2000)
  
      const response = await axios.post(downloadUrl, body, {
        headers: {
          "Content-Type": 'application/json',
        },
      });

      if(response.data.code !== 200) throw new Error(response.data.data);

      
      onDownloadProgress({
        status : 'Preparing Zip'
      })

      // const blob = new Blob([response.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = `${window.location.origin}/api/v1/download?file=${response.data.data}`;
      link.download = `data_${getCurrentDateTime()}.zip`;
      
      link.click();

      onDownloadProgress({
        status : 'Download Started'
      })

    } catch (error) {
      console.error('Error downloading file:', error);

    }

  };

  return (
    <div className="sidebar">
      {/* <InputGroup className="mb-4 mt-4">
        <Form.Control
          onChange={handleSearch}
          placeholder="Search Location"
          aria-label="location"
          aria-describedby="location"
        />
        <InputGroup.Text className="bg-dark text-light" id="location" >
          <CiSearch />
        </InputGroup.Text>
      </InputGroup> */}

      {/* {showSearchModal && filteredLocations.length != 0 && <div className="search-area">
        <ul>
          {
            filteredLocations.map((result, index) =>
              <li key={index} onClick={() => openLocationOnMap(result)}>
                <FaLocationDot /> &nbsp;
                <span>{result.label}</span>
              </li>
            )}
        </ul>
      </div>} */}


      {receivedData && <Fragment>
        <div className="mb-4">
          <h6> Selected Locations </h6>
          <div className="ms-1">
            <div className="text-muted font-13">
              <span> NorthEast : </span><span> {receivedData._northEast.lat}, {receivedData._northEast.lng} </span>
            </div>
            <div className="text-muted font-13">
              <span> SouthWest : </span><span> {receivedData._southWest.lat}, {receivedData._southWest.lng} </span>
            </div>
            <div className="text-muted font-13">
              <span> Total Selected area is {calculateDistance(receivedData).toFixed(2) + ' KM'}  </span>
            </div>
          </div>
        </div>

        {/* {downloadStatus == false && <div className="mb-4">
          <Button onClick={() => downloadFile()} size="sm" variant="dark"> <GrDownload className="me-1" /> Download File</Button>
          {/* <Button onClick={() => ShowPaymentModel(true)} size="sm" variant="dark"> <GrDownload className="me-1" /> Download File</Button> */}
          {/* <Button className="ms-3" size="sm" variant="secondary"> Cancel </Button> */}
        {/*</Fragment></div>} */}

        {downloadStatus != false && <div className="mb-4">
          <h6> {downloadStatus.status} </h6>
          {/* {downloadStatus.percent != false && <ProgressBar variant="dark" now={Math.round(downloadStatus.percent)} label={`${Math.round(downloadStatus.percent)}%`} />} */}
        </div>
        }

      </Fragment>
      }


      <hr />

      {/* <div className="mb-4 mt-4">
        <h6> Waiting Users</h6>
        <label className="text-muted font-13"> COMING SOON  </label>
      </div> */}

      {receivedData && <PaymentsModel
        show={ShowPaymentModel}
        amount={calculateDistance(receivedData)}
        onHide={() => setShowPaymentModel(false)}
        downLoadStart={downloadFile}
      />
      }

    </div>
  );
};
