import React, { Fragment, useEffect } from "react";
import "./sidebar.css";
import Button from 'react-bootstrap/Button';
import { GrDownload, GrWifiNone } from "react-icons/gr";
import PaymentsModel from "../../payments/PaymentsModel";
import { useState } from "react";
import axios from "axios";
// import { socket } from "../../../socket";
import { calculateDistance } from "../../../common";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import {CardSidebar} from "../../bootstrap/card/card";

var isDoownloadingInterval = false;

function getCurrentDateTime() {
  var now = new Date();
  var year = now.getFullYear();
  var month = ('0' + (now.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
  var day = ('0' + now.getDate()).slice(-2);
  var hours = ('0' + now.getHours()).slice(-2);
  var minutes = ('0' + now.getMinutes()).slice(-2);
  var seconds = ('0' + now.getSeconds()).slice(-2);

  var formattedDateTime = year + month + day + '_' + hours + minutes + seconds;
  return formattedDateTime;
}



//const provider = new OpenStreetMapProvider();

export const NewSidebar = ({ receivedData = false, props, handleMapCenter , modelLoaded}) => {

  // console.log('receivedData', receivedData)

  const [ShowPaymentModel, setShowPaymentModel] = React.useState(false);
 // const [filteredLocations, setFilteredLocations] = useState([]);
  const [downloadStatus, setDownloadStatus] = useState(false);
 // const [showSearchModal, setShowSearchModal] = useState(false)


  function onDownloadProgress(value) {
    setDownloadStatus(value)
  }


  useEffect(() => {
    
    if(downloadStatus){
      alert('Downloading already in Progress! Please wait to complete it');
      return false;
    }

  },[receivedData])



  const downloadFile = async () => {

   
    try {
      const downloadUrl = `${window.location.origin}/api/v1/download`;
      // const downloadUrl = `http://198.44.66.10:5001/api/v1/download`;
      // const downloadUrl = `http://densifyla.com/api/v1/download`;
  
      // const body=receivedData
      const body = {
        bbox_min_lon: receivedData._northEast.lng,
        bbox_min_lat: receivedData._northEast.lat,
        bbox_max_lon: receivedData._southWest.lng,
        bbox_max_lat: receivedData._southWest.lat,
      }

      onDownloadProgress({
        status : 'Preparing....'
      })
      

      if(isDoownloadingInterval){
        clearTimeout(isDoownloadingInterval)
      }

      isDoownloadingInterval = setTimeout(() =>{
        onDownloadProgress({
          status : 'Downloading....'
        })
    
      },2000)
  
      const response = await axios.post(downloadUrl, body, {
        headers: {
          "Content-Type": 'application/json',
        },
      });

      if(response.data.code !== 200) throw new Error(response.data.data);

      
      onDownloadProgress({
        status : 'Preparing Zip'
      })

      // const blob = new Blob([response.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = `${window.location.origin}/api/v1/download?file=${response.data.data}`;
      link.download = `data_${getCurrentDateTime()}.zip`;
      
      link.click();

      onDownloadProgress({
        status : 'Download Started'
      })

    } catch (error) {
      console.error('Error downloading file:', error);

    }

  };

  return (
    <div className="sidebar1">     

      {receivedData && <Fragment>
        <div className="mb-4">
          <div className="ms-1"> 
          <ListGroup as="ol" numbered>
      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" variant="info" >
        <div className="ms-2 me-auto">
          <div className="fw-bold">File Name: Terrain_Points</div>
          <strong>Format:</strong> DXF <br />
          <strong>Content: </strong>This file contains a 1m x 1m grid of points representing the bare earth terrain.<br />
          <strong>Uses: </strong>Can be imported into revit as cad format and used to create topo solid or topo surface.<br />
        </div>
        
      </ListGroup.Item>
      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
        <div className="ms-2 me-auto">
          <div className="fw-bold">File Name: Terrain_Mesh</div>
          <strong>Format:</strong> STL <br />
          <strong>Content: </strong>This file contains a mesh of the bare earth terrain.<br />
          <strong>Uses: </strong>Can be imported into most cad software.<br />
        </div>
        
      </ListGroup.Item>
      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" variant="info" >
        <div className="ms-2 me-auto">
          <div className="fw-bold">File Name: Parcels</div>
          <strong>Format:</strong> DXF <br />
          <strong>Content: </strong>This file contains parcels as closed polylines.<br />
          <strong>Uses: </strong>Can be imported into most cad software.<br />
        </div>
        
      </ListGroup.Item>
      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
        <div className="ms-2 me-auto">
          <div className="fw-bold">File Name: Buildings_2d</div>
          <strong>Format:</strong> DXF <br />
          <strong>Content: </strong>This file contains building footprints as closed polylines.<br />
          <strong>Uses: </strong>Can be imported into most cad software.<br />
        </div>
        
      </ListGroup.Item>
      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" variant="info" >
        <div className="ms-2 me-auto">
          <div className="fw-bold">File Name: Buildings_3d</div>
          <strong>Format:</strong> STL <br />
          <strong>Content: </strong>This file contains building meshes in 3d located at the correct elevation and the correct height.<br />
          <strong>Uses: </strong>Can be imported into most cad software.<br />
        </div>
        
      </ListGroup.Item>

    </ListGroup>           
            <div className="text-muted font-13">
              <span> Total Selected area is {calculateDistance(receivedData).toFixed(4) + ' KM'}  </span>
            </div>
          </div>
        </div>

        {modelLoaded && downloadStatus == false && <div className="mb-4">        
        {/* {downloadStatus == false && <div className="mb-4">  */}
          <div>
            <CardSidebar receivedData={receivedData} />
          </div>
          <Button class="button" onClick={() => downloadFile()} size="sm" variant="primary"> <GrDownload className="me-1" /> Download File</Button>
          
        </div>}

        {downloadStatus != false && <div className="mb-4">
          <h6> {downloadStatus.status} </h6>
          {/* {downloadStatus.percent != false && <ProgressBar variant="dark" now={Math.round(downloadStatus.percent)} label={`${Math.round(downloadStatus.percent)}%`} />} */}
        </div>
        }

      </Fragment>
      }


      <hr />     

      {receivedData && <PaymentsModel
        show={ShowPaymentModel}
        amount={calculateDistance(receivedData)}
        onHide={() => setShowPaymentModel(false)}
        downLoadStart={downloadFile}
      />
      }

    </div>
  );
};
