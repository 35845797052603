// Modal.js

import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const WelcomeModal = ({ showModal, handleClose }) => {
  
  return (
    <Modal show={showModal} onHide={handleClose} size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Welcome</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Welcome to Terrapin, Please enter an address and select the area you want to download on the map.</p>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleClose}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default WelcomeModal;
